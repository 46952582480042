<template>
  <Layout>
    <div class="ppcontainer regcontainer">
      <Header
        :back="true"
        regulation="pipl"
      />
      <h1
        class="title"
        :dir="dir"
      >
        奥飞特七中国儿童游戏隐私政策
      </h1>
      <div class="text notitle">
        <p>(最后更新于2024年10月21日)</p>

        <h2>关于我们和本隐私政策</h2>

        <p>
          奥飞特七深知儿童个人信息和隐私安全的重要性。为了更好地保护您所监护儿童的个人信息，除 <InternalLink
            route="/pipl/eula"
            text="《最终用户许可协议》"
          />
          （“用户协议”）和
          <InternalLink
            route="/pipl/privacy"
            text="《奥飞特七中国游戏隐私政策》"
          />
          (“《隐私政策》”)以外，我们还将通过
          <InternalLink
            route="/pipl/children-privacy"
            text="《奥飞特七中国儿童游戏隐私政策》"
          />
          （“本政策”）向您说明奥飞特七会如何收集、存储、保护、使用、共享及传输儿童个人信息（“儿童个人信息”），并说明您作为监护人所享有的权利。本政策是
          <InternalLink
            route="/pipl/privacy"
            text="《奥飞特七中国游戏隐私政策》"
          />

          的组成部分，如本政策条款与隐私政策条款有不一致的，以本政策为准。本隐私政策中，"奥飞特七"、"我们 "或 "我们的"指的是奥飞特七网络科技有限公司、绍兴欧飞特七网络科技有限公司、上海励挚网络科技有限公司以及其关联公司，例如奥飞特七有限责任公司（Outfit7 Limited）。
        </p>

        <p><strong>本政策中的“儿童”是指不满十四周岁的未成年人。儿童的个人信息属于敏感个人信息。在使用我们的游戏服务前，请儿童的父母或其他监护人（“您”）务必仔细阅读并透彻理解本政策，特别是以粗体标识的条款，您应重点阅读，在确认充分理解并同意后再允许您所监护的儿童开始使用我们的游戏。一旦您所监护的儿童开始使用我们的游戏，即表示您已充分理解并同意本政策。</strong></p>

        <p><strong>作为儿童的监护人，您应当指导儿童安全合理使用网络，关注儿童上网情况以及相关生理状况、心理状况、行为习惯，防范儿童接触危害或者可能影响其身心健康的网络信息，合理安排儿童使用网络的时间，预防和干预儿童沉迷网络。</strong></p>

        <h2>我们如何收集和使用儿童个人信息</h2>

        <p><strong>当儿童使用我们的游戏时，我们仅会收集您同意我们收集的、或您主动提供的有关儿童的个人信息。</strong>为了遵守国家法律法规及监管的要求，我们可能需要收集一些儿童的个人信息，以便向您的儿童提供游戏。我们已在《隐私政策》中，结合具体事项逐一说明了需要您同意我们收集的信息类型、用途目的及拒绝同意的后果。我们会按照《隐私政策》及本政策的约定使用儿童的个人信息。</p>
        <p><strong>当我们要将儿童个人信息用于《隐私政策》及本政策未载明的其他目的时，我们会按照法律法规的要求再次征得您的同意。</strong></p>

        <h2>我们如何分享儿童个人信息</h2>

        <p><strong>我们承诺对儿童个人信息进行严格保密，仅在符合本政策及您同意的情况下与第三方分享儿童个人信息。</strong>如果需要将儿童个人信息与第三方共享，我们会评估该第三方收集儿童个人信息的合法性、正当性和必要性。对于接收个人信息的公司，我们会与其签署严格的保密协定，要求其按照我们的要求、其他相关的保密及安全措施来处理个人信息。此外，我们会按照法律法规的要求征得您的授权同意。</p>

        <h2>我们如何储存、传输和保护儿童个人信息</h2>

        <p><strong>我们会按照《隐私政策》的约定存储和传输儿童个人信息。</strong>除法律法规或监管部门另有规定外，我们仅在所必需且最短时限内存储儿童个人信息。当超出最短必要期限时，我们将使用合理的措施对儿童个人信息进行删除或匿名化处理，以保护儿童信息免遭未经授权的访问或使用。</p>

        <p><strong>我们会采取加密及相关技术措施存储儿童个人信息，确保相关信息的安全。</strong>此外，我们对可能接触到儿童个人信息的员工采取了严格管控，并与其签署了相应的保密协议。如发生儿童个人信息泄露的安全事件，我们将启动应急预案、采取补救措施，并根据法律法规的要求将事件相关情况告知您及监管机构。您可以查阅我们的《隐私政策》以了解更多细节。</p>

        <h2>监护人的权利</h2>

        <p>在使用游戏期间，如您希望变更授权，或查询、复制、更正、补充、转移、删除儿童个人信息，可以随时通过游戏内客服、《隐私政策》或本政策的“联系我们”章节中所列的联系方式，联系我们来帮助您的儿童行使个人信息相关的权利。</p>

        <p><strong>在查询、复制、更正、补充、转移、删除前述儿童个人信息时，我们会要求您提供合理的证明材料以核实您的监护人身份。</strong>当我们收集到所需信息且能识别到相关儿童后，我们将尽快删除您要求删除的儿童个人信息。但您理解我们仍可能根据法律、行政法规的规定以匿名的形式保留部分或全部上述信息。您还随时可以通过卸载各台设备上我们的全部游戏，阻止我们进一步处理和收集儿童个人信息。</p>

        <h2>第三方条款及情况</h2>

        <p>我们的游戏可能包含第三方网站、产品或服务的链接。请注意上述网站、产品或服务并非由我们或由我们控制的人或公司开发及管理：我们无法控制这些人或公司的行为，他们的网站、产品或服务的内容，他们使用儿童个人信息的场景，或他们提供的任何产品或服务。我们向您及相关儿童展示第三方的相关链接并不表明我们与这些公司或个人之间存在赞助或隶属关系，也并不等同于我们对相关第三方的儿童隐私政策、信息安全及合法情况的表示认可。<strong>上述第三方在向您收集儿童个人信息（包括地理位置或联系方式等）时适用其各自的儿童隐私政策，我们鼓励您充分了解相关第三方的儿童隐私保护政策。</strong></p>

        <h2>隐私政策的更新</h2>

        <p>为了向您提供最新的资讯、应对法律和技术方面的变化以及最大限度地保护您所监护的儿童，我们将在必要时对本儿童隐私政策进行修订。我们对儿童个人信息的处理适用最新版的儿童隐私政策，您可以在此了解我们最新版本的儿童隐私政策。</p>

        <p>本儿童隐私政策更新后，我们将在游戏中发出更新版本并通过弹窗提示通知。<strong>您如果不同意我们的相关更新，可以要求您的儿童停止使用我们的游戏及服务。</strong></p>

        <h2>联系我们</h2>

        <p>如您对奥飞特七中国儿童游戏隐私政策有任何问题或疑虑，可以通过以下方式联系我们：</p>

        <ul>
          <li>
            关于儿童隐私问题和行使您作为监护人的权利，请联系privacy@outfit7networktech.com；
          </li>
          <li>
            我们的中国办公室：中国上海市徐汇区裕德路126号氪空间2130室。邮政编码：200030
          </li>
        </ul>

        <p>我们将尽快审核您的问题，并在核实您监护人身份后的15个工作日内回复。</p>


        <p><strong>奥飞特七中国儿童游戏隐私政策，2024年10月21日</strong></p>
      </div>
    </div>
  </Layout>
</template>

<script>
import Header from '@components/Header.vue'
import InternalLink from '@components/InternalLink.vue'

export default {
  metaInfo() {
    return {
      title: 'Outfit7 Compliance module',
      meta: [
        {
          key: 'description',
          name: 'description',
          content: 'Outfit7 Compliance module',
        },
      ],
    }
  },
  components: {
    Header,
    InternalLink
  },
  data() {
    return {
      content: {},
      orientation: '',
      lang: '',
    }
  },
  created() {
    this.content = this.$compliance.getLocalisedContent()
  },
  mounted() {
    this.orientation = this.$compliance.screenOrientation
    this.lang = this.$compliance.lang
    this.$compliance.forceRedraw()
  },
}
</script>

<style lang="scss">
.ppcontainer {
  position: relative;
  padding: 10px 0 10px 0;
  background: #fff;
  width: 100%;

  .logo {
    width: 20%;
    height: 40px;
    max-width: 130px;
    margin: 20px auto 0 auto;
    background-image: url('../../../assets/images/logo.jpg');
    background-repeat: no-repeat;
    background-size: contain;
  }
  .header {
    width: calc(100% - 60px);
    height: auto;
    margin: 0 30px;
  }
  .v-lazy-image {
    filter: blur(10px);
    transition: filter 0.7s;
  }
  .v-lazy-image-loaded {
    filter: blur(0);
  }
  .title {
    text-transform: uppercase;
    color: #4f5c68;
    font-size: 1.8em;
    font-weight: 800;
    line-height: 1em;
    text-align: center;
    width: 100%;
    display: inline-block;
    margin: 10px auto 10px auto;

    @media screen and (min-width: 768px) {
      font-size: 3.2em;
    }
    @media screen and (min-width: 1024px) {
      font-size: 5em;
    }
  }
  .text {
    padding: 0 10px 20px 10px;
    font-size: 1em;
    color: #4c5d6a;
    text-align: justify;
    &.noheader {
      margin: 0;
    }
    &.notitle {
      margin: 20px 0 0 0;
    }
    span {
      color: #688194;
      text-decoration: underline;
    }
    strong {
      color: #000;
    }
    @media screen and (min-width: 768px) {
      font-size: 1.8em;
      padding: 0 10px 20px 10px;
    }
    @media screen and (min-width: 1024px) {
      font-size: 2.1em;
    }
  }
}
.absolutefooter .tcf20container {
  height: 100%;
}
</style>
